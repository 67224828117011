/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  memo,
  FC,
  useState,
  useEffect,
  useMemo,
  Fragment,
  useRef,
  MutableRefObject,
} from 'react';
import { CONTENT_TYPE_COLLECTION, CONTENT_TYPE_CATEGORY } from '@constants';
import type {
  NavLinkedItem,
  NavColumn,
  NavCta,
  NavLinkGroup,
} from '@framework/api/types';
import GTM from '@utils/gtm';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import Sidebar from '@components/Sidebar';
import { useTranslation as t } from '@utils/hooks';
import Link from 'next/link';
import { IconNzxtLogo } from '@nzxt/react-icons';
import slugify from '@utils/slugify';

import MobileMenuButton from '@components/Layout/Header/MobileMenuButton';
import MobileHeaderLink from '../MobileHeaderLink';
import HeaderColumnLink from '../HeaderColumnLink';
import HeaderSubmenuCta from '../HeaderSubmenuCta';
import MobileUtilityLinks from '../MobileUtilityLinks';
import HeaderLink from '../HeaderLink';
import * as styles from './styles';

type Props = {
  title: string;
  index: number;
  handleOpen: () => void;
  handleClose: () => void;
  closeNav: () => void;
  focused?: number;
  isOpen?: boolean;
  topLevelLink?: NavLinkedItem;
  exploreLink?: NavLinkedItem;
  userInfo?: { title: string; href: string };
  columns?: NavColumn[];
  cta?: NavCta;
  promotedLinks?: NavLinkGroup[];
};

const MobileHeaderSubmenu: FC<Props> = ({
  title,
  topLevelLink,
  exploreLink,
  index,
  focused,
  handleClose,
  handleOpen,
  isOpen,
  closeNav,
  columns,
  cta,
  promotedLinks,
}) => {
  const [innerFocus, setinnerFocus] = useState(null);
  const navRefLevelOne = useRef(null) as MutableRefObject<HTMLDivElement>;
  const navRefLevelTwo = useRef(null) as MutableRefObject<HTMLDivElement>;

  // track logo click via GA and Heap
  const handleLogoClick = (): void => {
    GTM.dataLayer({
      dataLayer: {
        event: 'headerLogo',
      },
    });

    if (window.heap) {
      window.heap.track('headerLogo');
    }
  };

  const logoLabel = t('home');

  const handleClick = (name: string): void => {
    setinnerFocus(name);
  };

  const handleBackClick = (): void => {
    setinnerFocus(null);
  };

  useEffect(() => {
    if (!isOpen) {
      handleBackClick();
    }
  }, [isOpen]);

  const link = useMemo(() => {
    if (topLevelLink) {
      return getCmsLinkUrl({
        contentType: topLevelLink._modelApiKey,
        pageType: topLevelLink.pageType,
        slug: topLevelLink.slug,
      });
    }

    return null;
  }, [topLevelLink]);

  const exploreHref = useMemo(() => {
    if (exploreLink) {
      return getCmsLinkUrl({
        contentType: exploreLink._modelApiKey,
        slug: exploreLink.slug,
        parentSlug: exploreLink?.parentCategory?.slug,
      });
    }

    return null;
  }, [exploreLink]);

  const handlePromoLinkClick = (name: string): void => {
    const payload = {
      navherolinkName: name,
      navherolinkRefer: window.location.href,
      mainnavSection: title,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'navheromenuClickPromo',
        ...payload,
      },
    });

    if (window.heap) {
      window.heap.track('navheromenuClickPromo', payload);
    }
  };

  return (
    <li className={styles.flexCol}>
      <MobileHeaderLink href={link} title={title} handleClick={handleOpen} />
      {columns && columns.length > 0 && (
        <Sidebar
          open={focused === index}
          onClose={() => closeNav()}
          isMobileOnly
          isInnerItem
          isScrollable
          customRef={navRefLevelOne}
        >
          <div className={styles.shrink}>
            <MobileMenuButton />
            <Link href="/" passHref>
              <a
                onClick={() => handleLogoClick()}
                onKeyDown={() => handleLogoClick()}
                role="link"
                tabIndex={0}
                aria-label={`NZXT ${logoLabel}`}
              >
                <IconNzxtLogo className={styles.mobileLogo} />
              </a>
            </Link>
          </div>
          <ul className={styles.getOpenClass(focused === index)}>
            <li className={styles.verticalSpacing}>
              <MobileHeaderLink
                title={title}
                handleClick={handleClose}
                isBack
              />
            </li>
            {promotedLinks?.length > 0 && (
              <li>
                {promotedLinks.map(promo => (
                  <HeaderLink
                    title={promo.title}
                    href={getCmsLinkUrl({
                      contentType: promo?.link?._modelApiKey,
                      pageType: promo?.link?.pageType,
                      slug: promo?.link?.slug,
                      parentSlug: promo?.link?.parentCategory?.slug,
                    })}
                    navIcon={promo?.headingIcon}
                    handleLinkClick={handlePromoLinkClick}
                    showLink
                    key={promo.title}
                  />
                ))}
              </li>
            )}
            {columns?.map((column, n) => (
              <Fragment key={`column-${n}`}>
                {column?.categories.map(category => {
                  // track link clicks via GA and Heap
                  const handleLinkClick = (name: string): void => {
                    const payload = {
                      navherolinkName: name,
                      navherolinkRefer: window.location.href,
                      mainnavSection: category.name,
                    };

                    GTM.dataLayer({
                      dataLayer: {
                        event: 'navheromenuClick',
                        ...payload,
                      },
                    });

                    if (window.heap) {
                      window.heap.track('navheromenuClick', payload);
                    }
                  };

                  return (
                    <li className={styles.fullWidth} key={category.slug}>
                      {category.collections.length > 0 ? (
                        <MobileHeaderLink
                          title={category.name}
                          handleClick={() => handleClick(category.name)}
                        />
                      ) : (
                        <MobileHeaderLink
                          title={category.name}
                          href={getCmsLinkUrl({
                            contentType: CONTENT_TYPE_CATEGORY,
                            slug: category.slug,
                          })}
                        />
                      )}
                      {category.collections.length > 0 && (
                        <Sidebar
                          open={
                            innerFocus === category.name && focused !== null
                          }
                          onClose={() => closeNav()}
                          isMobileOnly
                          isScrollable
                          isInnerItem
                          customRef={navRefLevelTwo}
                        >
                          <div className={styles.shrink}>
                            <MobileMenuButton />
                            <Link href="/" passHref>
                              <a
                                onClick={() => handleLogoClick()}
                                onKeyDown={() => handleLogoClick()}
                                role="link"
                                tabIndex={0}
                                aria-label={`NZXT ${logoLabel}`}
                              >
                                <IconNzxtLogo className={styles.mobileLogo} />
                              </a>
                            </Link>
                          </div>
                          <ul
                            className={styles.getOpenClass(
                              innerFocus === category.name && focused !== null
                            )}
                          >
                            <li className={styles.verticalSpacing}>
                              <MobileHeaderLink
                                title={category.name}
                                handleClick={handleBackClick}
                                isBack
                                handleLinkClick={handleLinkClick}
                              />
                            </li>
                            {exploreHref && (
                              <li>
                                <MobileHeaderLink
                                  href={exploreHref}
                                  title="Explore"
                                />
                              </li>
                            )}

                            {category.collections.map(collection => (
                              <li
                                className={styles.fullWidth}
                                key={collection.slug}
                              >
                                <MobileHeaderLink
                                  title={collection.name}
                                  href={getCmsLinkUrl({
                                    contentType: CONTENT_TYPE_COLLECTION,
                                    slug: collection.slug,
                                  })}
                                  shortDescription={collection.shortDescription}
                                />
                              </li>
                            ))}

                            <MobileUtilityLinks
                              idLabel={`${category?.name}-${category?.parentCategory?.slug}`}
                            />
                          </ul>
                        </Sidebar>
                      )}
                    </li>
                  );
                })}

                {Array.isArray(column.linkGroup) &&
                  column.linkGroup.map(l => {
                    // track link clicks via GA and Heap
                    const handleLinkClick = (name: string): void => {
                      const payload = {
                        navherolinkName: name,
                        navherolinkRefer: window.location.href,
                        mainnavSection: l.title,
                      };

                      GTM.dataLayer({
                        dataLayer: {
                          event: 'navheromenuClick',
                          ...payload,
                        },
                      });

                      if (window.heap) {
                        window.heap.track('navheromenuClick', payload);
                      }
                    };

                    return (
                      <li className={styles.fullWidth} key={slugify(l.title)}>
                        <HeaderColumnLink
                          key={slugify(l.title)}
                          cols={1}
                          links={l.links}
                          sectionTitle={l.title}
                          iconComponent={l.headingIcon}
                          handleLinkClick={handleLinkClick}
                          isMobileNav
                          hideOnSmall
                          href={
                            l?.link?.slug && l?.link?._modelApiKey
                              ? getCmsLinkUrl({
                                  contentType: l?.link?._modelApiKey,
                                  pageType: l?.link?.pageType,
                                  slug: l?.link?.slug,
                                  parentSlug: l?.link?.parentCategory?.slug,
                                })
                              : null
                          }
                        />
                      </li>
                    );
                  })}
              </Fragment>
            ))}
            <MobileUtilityLinks idLabel={title} />
            {cta && (
              <li className={styles.cta}>
                <div className={styles.ctaWrapper}>
                  <HeaderSubmenuCta {...cta} />
                </div>
              </li>
            )}
          </ul>
        </Sidebar>
      )}
    </li>
  );
};

export default memo(MobileHeaderSubmenu);
