import { classnames } from '@tailwindcss-classnames';

const dark = classnames('bg-nzxt-light-grey-50');
const light = classnames('bg-white');

export const bodyBg = (isDark: boolean): string =>
  classnames('flex', 'flex-col', 'relative', 'h-full', 'grow', {
    [dark]: isDark,
    [light]: !isDark,
  });

export const stickFooterContent = classnames('flex-1');

export const overlay = classnames(
  'fixed',
  'top-0',
  'bottom-0',
  'left-0',
  'right-0',
  'bg-nzxt-dark-grey-700',
  'opacity-50',
  'h-screen',
  'w-screen',
  'z-10'
);

export const pageWrapper = classnames(
  'flex',
  'min-h-screen',
  'flex-col',
  'bg-nzxt-dark-grey-900',
  'h-full'
);
export const navWrapper = classnames('h-full', 'sticky', 'top-0', 'z-40');
