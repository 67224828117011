/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import Link from 'next/link';
import GTM from '@utils/gtm';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import type { FooterLink } from '@framework/api/types';
import * as styles from './styles';

const FooterLegal: FC<FooterLink> = ({ title, slug, _modelApiKey }) => {
  // track link clicks via GA and Heap
  const handleLinkClick = (): void => {
    const payload = {
      footerEndLink: title,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'footerEnd',
        ...payload,
      },
    });

    if (window.heap) {
      window.heap.track('footerEnd', payload);
    }
  };

  return (
    <Link
      key={`footer-legal-link-${slug}`}
      href={`${process.env.NEXT_PUBLIC_ATLAS_URL}${getCmsLinkUrl({
        contentType: _modelApiKey,
        slug,
      })}`}
      passHref
    >
      <a
        rel="noopener noreferrer"
        target="_blank"
        className={styles.anchor}
        onClick={() => handleLinkClick()}
        onKeyDown={() => handleLinkClick()}
        tabIndex={0}
        role="link"
      >
        {title}
      </a>
    </Link>
  );
};

export default memo(FooterLegal);
