import { memo, FC } from 'react';
import {
  IconFacebook,
  IconInstagram,
  IconTwitter,
  IconYouTube,
  IconTwitch,
  IconTikTok,
  IconReddit,
  IconDiscord,
} from '@nzxt/react-icons';
import GTM from '@utils/gtm';
import type { SocialLink } from '@framework/api/types';
import * as styles from './styles';

const ICON_MAP = {
  facebook: <IconFacebook className={styles.iconSvg} />,
  instagram: <IconInstagram className={styles.iconSvg} />,
  twitter: <IconTwitter className={styles.iconSvg} />,
  youtube: <IconYouTube className={styles.iconSvg} />,
  twitch: <IconTwitch className={styles.iconSvg} />,
  tiktok: <IconTikTok className={styles.iconSvg} />,
  reddit: <IconReddit className={styles.iconSvg} />,
  discord: <IconDiscord className={styles.iconSvg} />,
};

type Props = SocialLink & { idSuffix?: string };

const FooterSocialIcon: FC<Props> = ({
  text,
  link,
  iconComponent,
  idSuffix,
}) => {
  // track link clicks via GA and Heap
  const handleLinkClick = (): void => {
    const payload = {
      socNetwork: text,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'footerSocial',
        ...payload,
      },
    });

    if (window.heap) {
      window.heap.track('footerSocial', payload);
    }
  };

  return (
    <a
      href={link}
      id={`social-link-${text}${idSuffix ? `-${idSuffix}` : ''}`}
      className={styles.iconA}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => handleLinkClick()}
      onKeyDown={() => handleLinkClick()}
      tabIndex={0}
    >
      <span className={styles.iconSpan}>{text}</span>
      {ICON_MAP[iconComponent.toLowerCase()]}
    </a>
  );
};

export default memo(FooterSocialIcon);
