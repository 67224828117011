import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';

type Props = {
  errorMessage?: string;
  supportingMessage?: string;
};

const ToastMessage: FC<Props> = ({ errorMessage, supportingMessage }) => {
  const errorMess = errorMessage?.toLowerCase().includes('maxify')
    ? null
    : errorMessage;

  return (
    <>
      {errorMess && <p className="font-bold">{errorMess}</p>}
      <p>{supportingMessage || t('message_error')}</p>
    </>
  );
};

export default memo(ToastMessage);
