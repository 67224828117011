import { ReactText, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { toast } from 'react-toastify';

const toastAlert = (
  type: 'success' | 'info' | 'warning' | 'error' | 'default',
  toastPosition: 'right' | 'left',
  errorMessage: ReactNode,
  error?: {
    message: string;
    type: string;
    name: string;
  },
  toastId?: string
): ReactText => {
  const options = {
    autoClose: 7000,
    type: type || toast.TYPE.DEFAULT,
    hideProgressBar: false,
    position:
      toastPosition === 'right'
        ? toast.POSITION.BOTTOM_RIGHT
        : toast.POSITION.BOTTOM_LEFT,
    pauseOnHover: true,
    draggablePercent: 60,
    toastId,
  };

  if (error) {
    Sentry.captureException(error);
  }

  if (type && errorMessage) {
    return toast(errorMessage, options);
  }
  if (type && !errorMessage) {
    return toast('ALERT MESSAGE', options);
  }
  return null;
};

export default toastAlert;
