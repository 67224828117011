/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import Link from 'next/link';
import { IconChevronRight } from '@nzxt/react-icons';
import { useMedia } from '@utils/hooks';
import IconDynamic from '@components/IconDynamic';
import * as styles from './styles';

type Props = {
  title: string;
  href?: string;
  navIcon?: string;
  goByThree?: boolean;
  showLink?: boolean;
  handleLinkClick: (name: string) => void;
  hideOnSmall?: boolean;
};

const HeaderLink: FC<Props> = ({
  title,
  href,
  navIcon,
  goByThree,
  handleLinkClick,
  showLink = false,
  hideOnSmall = false,
}) => {
  const sectionTitle = title.toLowerCase().replace(/[^\w]/g, '');
  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const isLg = !isSm && !isMd;
  const hide = hideOnSmall && !isLg;

  return sectionTitle ? (
    <>
      {!hide && (
        <>
          {showLink && href.length > 0 ? (
            <Link href={href}>
              <a
                className={styles.submenuListHeaderLink(goByThree)}
                onClick={() => handleLinkClick(title)}
                onKeyDown={() => handleLinkClick(title)}
                role="link"
                tabIndex={0}
                id={`nav-link-${title}-${isLg ? 'lg' : 'sm'}`}
                data-test-id={`nav-link-${title}`}
              >
                <div className={styles.flexEnd}>
                  {sectionTitle && navIcon && (
                    <IconDynamic iconName={navIcon} className={styles.icon} />
                  )}{' '}
                  {title}
                </div>
                <IconChevronRight className={styles.chevron} />
              </a>
            </Link>
          ) : (
            <div
              className={styles.submenuListHeader(goByThree)}
              id={`nav-link-${title}-${isLg ? 'lg' : 'sm'}`}
              data-test-id={`nav-link-${title}`}
            >
              <div className={styles.flexEnd}>
                {sectionTitle && navIcon && (
                  <IconDynamic iconName={navIcon} className={styles.icon} />
                )}
                {title}
              </div>
            </div>
          )}
        </>
      )}
    </>
  ) : null;
};

export default memo(HeaderLink);
