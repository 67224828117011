import { FC, memo, ReactNode } from 'react';
import '@reach/dialog/styles.css';
import cx from 'clsx';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { IconX } from '@nzxt/react-icons';
import * as styles from './styles';
import extraStyles from './Modal.module.css';

type Props = {
  className?: string;
  children?: ReactNode;
  open?: boolean;
  onClose: () => void;
  ariaLabel: () => string;
  disableClose?: boolean;
  isInverted?: boolean;
  isWide?: boolean;
};

const Modal: FC<Props> = ({
  children,
  open,
  onClose,
  ariaLabel,
  disableClose = false,
  isInverted = false,
  isWide = false,
}) => (
  <DialogOverlay isOpen={open} className={styles.z} onDismiss={onClose}>
    {open ? (
      <DialogContent
        className={cx(extraStyles.dataOverlayScroll, {
          [extraStyles.dataOverlay]: !isWide,
          [extraStyles.dataOverlayWide]: isWide,
          [extraStyles.inverted]: isInverted,
        })}
        aria-label={ariaLabel()}
      >
        <div className={styles.buttonWrapper}>
          {!disableClose && (
            <button onClick={onClose} aria-label="Close panel" type="button">
              <IconX className={styles.iconClose(isInverted)} />
            </button>
          )}
        </div>
        {children}
      </DialogContent>
    ) : null}
  </DialogOverlay>
);

export default memo(Modal);
