/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, memo } from 'react';
import SupportButton from '@components/Layout/Header/SupportButton';
import UserSection from '@components/Layout/Header/MobileMenu/UserSection';
import * as styles from './styles';

type Props = {
  idLabel?: string;
};

const MobileUtilityLinks: FC<Props> = ({ idLabel }) => {
  const utilityLinks = [
    <SupportButton iconStyles="text-nzxt-volt-400" />,
    <UserSection idLabel={idLabel} />,
  ];
  return (
    <>
      {utilityLinks &&
        utilityLinks.map((link, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`utilityLink-${i}`} className={styles.utilityLink}>
            {link}
          </li>
        ))}
    </>
  );
};

export default memo(MobileUtilityLinks);
