/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useState,
  useRef,
  FC,
  useEffect,
  MutableRefObject,
  // useMemo,
} from 'react';
// import { getRegion } from '@framework/api/utils/maxify';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from '@utils/scroll-lock/bodyScrollLock';
import { useMedia /* useTranslation as t */ } from '@utils/hooks';
// import Link from 'next/link';
import { IconNzxtLogo } from '@nzxt/react-icons';
import Router /* useRouter */ from 'next/router';
import debounce from 'lodash.debounce';
// import GTM from '@utils/gtm';
import ClickOutside from '@utils/click-outside';
import type { DatoNav } from '@framework/api/types';
import useUiStore, {
  getDisplayNav,
  getCloseNav,
  getDisplayDesktopNav,
  getCloseDesktopNav,
  // getOpenDesktopNav,
} from '@stores/use-ui-store';
import BannerContainer from '@components/Layout/BannerContainer';
// import MarketingBanner from '@components/Layout/MarketingBanner';
// import HeaderMenu from './HeaderMenu';
// import ProfileDropdown from './ProfileDropdown';
// import SupportButton from './SupportButton';
// import MobileMenuButton from './MobileMenuButton';
import HeaderWrapper from './HeaderWrapper';
// import CartButton from './CartButton';
// import SearchButton from './SearchButton';
// import SearchBar from './SearchBar';
import * as styles from './styles';

type Props = {
  data?: DatoNav;
  displayPreviewBanner?: boolean;
};

const Header: FC<Props> = ({ data, displayPreviewBanner }) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const skipRef = useRef() as MutableRefObject<HTMLDivElement>;
  // const marketingBanner = data?.marketingBanner ?? null;
  // const { locale } = useRouter();

  // const region = getRegion(locale);
  // const regionalMarketingBanner = useMemo(
  //   () =>
  //     Array.isArray(marketingBanner)
  //       ? marketingBanner.filter(c =>
  //           c.bannerRegion?.some(r => r.region === region)
  //         )
  //       : null,
  //   [marketingBanner, region]
  // );

  // const hasMarketingBanner =
  //   Array.isArray(regionalMarketingBanner) &&
  //   regionalMarketingBanner?.length > 0;

  // const displayToast = useToastStore(getDisplayToast);

  const displayNav = useUiStore(getDisplayNav);
  const closeNav = useUiStore(getCloseNav);
  const displayDesktopNav = useUiStore(getDisplayDesktopNav);
  // const openDesktopNav = useUiStore(getOpenDesktopNav);
  const closeDesktopNav = useUiStore(getCloseDesktopNav);

  const [focused, setFocused] = useState(null);

  const handleUnfocused = (): void => {
    skipRef?.current?.focus();
    setFocused(null);
    closeNav();
    closeDesktopNav();
  };

  // const handleFocused = (index: number): void => {
  //   setFocused(index);
  //   openDesktopNav();

  //   if (focused === index) {
  //     handleUnfocused();
  //   }
  // };

  const debouncedUnfocus = debounce(handleUnfocused, 250);

  useEffect(() => {
    if (ref.current) {
      if (displayNav || focused !== null) {
        disableBodyScroll(ref.current, {
          reserveScrollBarGap: true,
        });
      } else {
        enableBodyScroll(ref.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [displayNav, focused, ref]);

  useEffect(() => {
    if (!displayDesktopNav) {
      setFocused(null);
    }
  }, [displayDesktopNav]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        handleUnfocused();
      }
    };

    Router.events.on('routeChangeComplete', debouncedUnfocus);
    window.addEventListener('keydown', handleKeydown);

    return () => {
      Router.events.off('routeChangeComplete', debouncedUnfocus);
      window.removeEventListener('keydown', handleKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUnfocus]);

  // track logo click via GA and Heap
  // const handleLogoClick = (): void => {
  //   GTM.dataLayer({
  //     dataLayer: {
  //       event: 'headerLogo',
  //     },
  //   });

  //   if (window.heap) {
  //     window.heap.track('headerLogo');
  //   }
  // };

  // const logoLabel = t('home');

  const isMd = useMedia('md');
  const isSm = useMedia('sm');
  const isLg = !isMd && !isSm;

  const hasData = Array.isArray(data?.panes) && data?.panes?.length > 0;

  return hasData ? (
    <HeaderWrapper>
      <div>
        <span tabIndex={-1} ref={skipRef}>
          <span className={styles.srOnly}>
            Skip to <a href="#main">content</a> or <a href="#footer">footer</a>
          </span>
        </span>
      </div>

      <BannerContainer preview={displayPreviewBanner} />
      {/* {hasMarketingBanner ? (
        <div className={styles.marketingBannerWrapper}>
          <MarketingBanner marketingBannerData={regionalMarketingBanner} />
        </div>
      ) : null} */}

      <ClickOutside
        active={(isLg && displayNav) || focused !== null}
        onClick={() => handleUnfocused()}
      >
        <div className={styles.topBarContainer}>
          <div className={styles.topInnerContainer} ref={ref}>
            <div className={styles.shrink}>
              {/* <MobileMenuButton />
              <Link href="/" passHref>
                <a
                  onClick={() => handleLogoClick()}
                  onKeyDown={() => handleLogoClick()}
                  role="link"
                  tabIndex={0}
                  aria-label={`NZXT ${logoLabel}`}
                > */}
              <IconNzxtLogo className={styles.mobileLogo} />
              {/* </a>
              </Link> */}
            </div>

            <div className={styles.bottomWrapper}>
              <div className={styles.bottomBarContainer}>
                {/* <Link href="/" passHref>
                  <a
                    onClick={() => handleLogoClick()}
                    onKeyDown={() => handleLogoClick()}
                    role="link"
                    tabIndex={0}
                    aria-label={`NZXT ${logoLabel}`}
                  > */}
                <IconNzxtLogo className={styles.logo} />
                {/* </a>
                </Link> */}
                {/* <ul className={styles.linkContainer} role="menu">
                  {data &&
                    data.panes &&
                    data.panes.length > 0 &&
                    data.panes.map((pane, i) => (
                      <HeaderMenu
                        key={pane.title}
                        {...pane}
                        index={i}
                        handleOpen={() => handleFocused(i)}
                        focused={focused}
                      />
                    ))}
                </ul> */}
              </div>
            </div>
            {/* <div className={styles.dropdownContainerBase}>
              <span>
                <SearchButton />
              </span>
              <span className={styles.hiddenSmall}>
                <SupportButton iconStyles={styles.supportButton} />
              </span>
              <span className={styles.hiddenSmall}>
                <ProfileDropdown
                  marketingBannerData={regionalMarketingBanner}
                />
              </span> */}
            {/*
              <span>
                <CartButton key={`cart-button-${region}`} />
              </span>
              */}
            {/* </div> */}
          </div>
        </div>
      </ClickOutside>
    </HeaderWrapper>
  ) : null;
};

export default Header;
