import { classnames } from '@tailwindcss-classnames';

export const fullWidth = classnames('w-full', 'py-1', 'my-1');

export const fullWidthButton = classnames(
  fullWidth,
  'flex',
  'items-center',
  'text-left',
  'capitalize',
  'px-4',
  'focus:outline-none'
);

export const baseClass = classnames(
  fullWidth,
  'block',
  'text-base',
  'focus:outline-none',
  'text-nzxt-light-grey-800',
  'font-semibold',
  'capitalize'
);

const backLinkStyles = classnames('text-center', 'py-2');
export const buttonLink = (isBack: boolean): string =>
  classnames(
    {
      [backLinkStyles]: isBack,
    },
    'w-full',
    baseClass,
    'focus:outline-none'
  );

export const linkClass = classnames(fullWidth, baseClass, 'px-4');

export const mobileChevron = classnames('block', 'lg:hidden');
export const description = classnames(
  'block',
  'text-xxs',
  'leading-tight',
  'lg:text-nzxt-light-grey-400',
  'font-thin',
  'w-40'
);
