/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { IconProfile } from '@nzxt/react-icons';
import useUiStore, {
  getSetModalView,
  getOpenModal,
  getCloseDrawer,
} from '@stores/use-ui-store';
import { useAuth, useTranslation as t } from '@utils/hooks';
import { MINIMAL_PROFILE_LINKS } from '@constants';
import useLogout from '@framework/auth/use-logout';
import toastAlert from '@components/ToastAlert';
import ToastMessage from '@components/ToastAlert/ToastMessage';
import * as styles from './styles';

type Props = {
  darkModeBuild?: boolean;
  idLabel?: string;
};

const UserSection: FC<Props> = ({ darkModeBuild, idLabel }) => {
  const setModalView = useUiStore(getSetModalView);
  const openModal = useUiStore(getOpenModal);
  const closeDrawer = useUiStore(getCloseDrawer);

  const router = useRouter();
  const { isLoggedIn } = useAuth();

  const MESSAGE_USER_SIGN_IN_SIGN_UP = t('user_sign_in_sign_up');
  const MAP = {
    user_settings_title: t('user_settings_title'),
    user_saved_builds_heading: t('user_saved_builds_heading'),
  };

  const handleOnCLick = (): void => {
    closeDrawer();
    openModal();
    setModalView('LOGIN_VIEW');
  };

  const logout = useLogout();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
    } catch (err) {
      toastAlert(
        'error',
        'left',
        <ToastMessage errorMessage={err.message} />,
        err
      );
    } finally {
      const res = await signOut({
        redirect: false,
        callbackUrl: process.env.NEXT_PUBLIC_BASE_URL,
      });
      router.push(res.url);
    }
  };
  const MESSAGE_USER_SIGN_OUT = t('user_sign_out');

  return (
    <>
      {isLoggedIn ? (
        <ul>
          {MINIMAL_PROFILE_LINKS.map(userPane => (
            <li key={userPane.href} className={styles.profileLinkListItem}>
              <Link href={userPane.href}>
                <a className={styles.profileLink(darkModeBuild)}>
                  {MAP[userPane.title]}
                </a>
              </Link>
            </li>
          ))}
          <li className={styles.profileLinkListItem}>
            <button
              type="button"
              onClick={handleLogout}
              className={styles.profileLinkLogout(darkModeBuild)}
            >
              {MESSAGE_USER_SIGN_OUT}
            </button>
          </li>
        </ul>
      ) : (
        <button
          className={styles.button(darkModeBuild)}
          id={`user-menu-mobile-${darkModeBuild ? 'bld' : 'dflt'}${
            idLabel ? `-${idLabel}` : ''
          }`}
          aria-haspopup="true"
          onClick={handleOnCLick}
          type="button"
        >
          <IconProfile className={styles.profileSvg(darkModeBuild)} />
          <span>{MESSAGE_USER_SIGN_IN_SIGN_UP}</span>
        </button>
      )}
    </>
  );
};

export default memo(UserSection);
