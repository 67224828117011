import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import parseLocale from '@utils/parse-locale';
import { IconInfo, IconX, IconArrowRight } from '@nzxt/react-icons';
import { useTranslation as t } from '@utils/hooks';
import useToastStore, {
  getCloseToast,
  getToastText,
  getToastSmallText,
  getToastCountryText,
  getToastButtonText,
  getDismissToast,
  getToastAction,
  getToastCountryLink,
  getToastShop,
} from '@stores/use-toast-store';
import useGeoStore, { getGeo } from '@stores/use-geo-store';
import {
  DEFAULT_LANGUAGE_CODE,
  COUNTRY_TO_COUNTRY_CODE_MAP,
  SUPPORTED_LOCATION,
  GEOIP_CONTINENT_TO_LOCATION_MAP,
  COUNTRY_NAME_UNITED_STATES,
  COUNTRY_NAME_UNITED_KINGDOM,
  COUNTRY_NAME_BELGIUM,
  COUNTRY_NAME_FRANCE,
  COUNTRY_NAME_ITALY,
  COUNTRY_NAME_GERMANY,
  COUNTRY_NAME_LUXEMBOURG,
  COUNTRY_NAME_AUSTRALIA,
  COUNTRY_NAME_NEW_ZEALAND,
  COUNTRY_NAME_JAPAN,
  COUNTRY_NAME_SPAIN,
  COUNTRY_NAME_NETHERLANDS,
  COUNTRY_NAME_CANADA,
  COUNTRY_NAME_AUSTRIA,
} from '@constants';
import useUiStore, { getDisplayMarketingBanner } from '@stores/use-ui-store';
import * as styles from './styles';

type Props = {
  flyout?: boolean;
};

const NotificationBanner: FC<Props> = ({ flyout }) => {
  const router = useRouter();
  const geo = useGeoStore(getGeo);
  const COUNTRY_TRANSLATION_MAP = {
    [COUNTRY_NAME_UNITED_STATES]: t('country_us'),
    [COUNTRY_NAME_CANADA]: t('country_canada'),
    [COUNTRY_NAME_AUSTRIA]: t('country_austria'),
    [COUNTRY_NAME_BELGIUM]: t('country_belgium'),
    [COUNTRY_NAME_FRANCE]: t('country_france'),
    [COUNTRY_NAME_ITALY]: t('country_italy'),
    [COUNTRY_NAME_LUXEMBOURG]: t('country_luxembourg'),
    [COUNTRY_NAME_GERMANY]: t('country_germany'),
    [COUNTRY_NAME_NETHERLANDS]: t('country_netherlands'),
    [COUNTRY_NAME_SPAIN]: t('country_spain'),
    [COUNTRY_NAME_UNITED_KINGDOM]: t('country_uk'),
    [COUNTRY_NAME_AUSTRALIA]: t('country_australia'),
    [COUNTRY_NAME_NEW_ZEALAND]: t('country_nz'),
    [COUNTRY_NAME_JAPAN]: t('country_japan'),
  };
  const { asPath, locale, defaultLocale } = router;
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang = parsedLocale[0] || DEFAULT_LANGUAGE_CODE;
  const desiredLocation = GEOIP_CONTINENT_TO_LOCATION_MAP(geo);

  const toastText = useToastStore(getToastText);
  const toastShop = useToastStore(getToastShop);
  const toastCountryLink = useToastStore(getToastCountryLink);
  const toastSmallText = useToastStore(getToastSmallText);
  const toastCountryText = useToastStore(getToastCountryText);
  const toastButtonText = useToastStore(getToastButtonText);
  const closeToast = useToastStore(getCloseToast);
  const dismissToast = useToastStore(getDismissToast);
  const toastAction = useToastStore(getToastAction);
  const displayMarketingBanner = useUiStore(getDisplayMarketingBanner);
  const isFlyout = flyout ? 'flyout' : 'default';

  const switchLocale = (newLocation: string): void => {
    router.push(asPath, asPath, {
      locale: `${lang}-${newLocation.toUpperCase()}`,
    });
  };

  return (
    <div
      className={
        lang === 'nl'
          ? styles.getWrapperStyleLongText(displayMarketingBanner)
          : styles.getWrapperStyle(displayMarketingBanner)
      }
    >
      <div className={styles.noticeContainer}>
        <div className={styles.currentRegionContainer}>
          <span className={styles.noticeIconContainer}>
            <IconInfo className={styles.icon} />
          </span>
          <div className={styles.currentRegionTextContainer}>
            <div className={styles.smallText}>
              {toastSmallText}&nbsp;
              <span className={styles.currentRegionText}>
                {COUNTRY_TRANSLATION_MAP?.[toastCountryText] ||
                  toastCountryText}
              </span>
            </div>
          </div>
        </div>
        {desiredLocation === SUPPORTED_LOCATION[geo.countryCode] ? (
          <p className={styles.countryText}>
            {toastShop}{' '}
            <button
              type="button"
              className={styles.switchCountry}
              onClick={() =>
                switchLocale(COUNTRY_TO_COUNTRY_CODE_MAP[toastCountryLink])
              }
            >
              {COUNTRY_TRANSLATION_MAP?.[toastCountryLink] || toastCountryLink}
            </button>{' '}
            {toastText}
          </p>
        ) : (
          <div className={styles.regionNoticeText[isFlyout]}>{toastText}</div>
        )}
        {flyout && (
          <>
            <span
              className={styles.flyoutCaret}
              style={{ zIndex: -1, top: '-24px', right: '12px' }}
            />
            <button
              type="button"
              className={styles.flyoutButton}
              onClick={() => {
                closeToast();
                dismissToast();
              }}
            >
              <span className={styles.screenReaderText}>Dismiss</span>
              <IconX className={styles.icon} />
            </button>
          </>
        )}
      </div>
      {!flyout && (
        <div className={styles.regionButtonContainer}>
          {toastButtonText && (
            <button
              type="button"
              onClick={() => toastAction()}
              className={styles.link}
            >
              {toastButtonText}
              <IconArrowRight className={styles.arrowStyles} />
            </button>
          )}
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              closeToast();
              dismissToast();
            }}
          >
            <span className={styles.screenReaderText}>Dismiss</span>
            <IconX className={styles.icon} />
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(NotificationBanner);
