import { classnames } from 'tailwindcss-classnames';

const baseClass = classnames('h-6', 'w-6');

const activeClass = classnames('hidden');

const inactiveClass = classnames('block');

const inverted = classnames('text-white', 'stroke-current');

export const getOpenClass = (isOpen: boolean, isInverted: boolean): string =>
  classnames(baseClass, {
    [activeClass]: isOpen,
    [inactiveClass]: !isOpen,
    [inverted]: isInverted,
  });

export const container = classnames('mx-2', 'flex', 'lg:hidden');

export const button = classnames(
  'inline-flex',
  'items-center',
  'justify-center',
  'py-2',
  'rounded-md',
  'text-gray-700',
  'focus:outline-none'
);
