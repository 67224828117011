/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import Link from 'next/link';
import cn from 'clsx';
import { IconHelpAndSupport } from '@nzxt/react-icons';
import { ROUTE_SUPPORT } from '@constants';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

type Props = {
  iconStyles?: string;
  darkModeBuild?: boolean;
};

const SupportButton: FC<Props> = ({ iconStyles, darkModeBuild }) => {
  const LABEL_SUPPORT = t('label_support');

  return (
    <Link href={`/${ROUTE_SUPPORT}`} passHref>
      <a className={styles.linkStyle(darkModeBuild)} aria-label={LABEL_SUPPORT}>
        {!darkModeBuild && (
          <IconHelpAndSupport className={cn(iconStyles, styles.supportSvg)} />
        )}
        <span className={styles.label}>{LABEL_SUPPORT}</span>
      </a>
    </Link>
  );
};

export default memo(SupportButton);
