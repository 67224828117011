import { classnames } from '@tailwindcss-classnames';

const spacing = classnames('px-4', 'sm:px-6', 'xl:px-12');
export const flexCenter = classnames('flex', 'items-center');
export const hiddenSmall = classnames('w-full', 'lg:flex', 'hidden');
export const shrink = classnames('flex-shrink-0', 'ml-0', flexCenter);
export const srOnly = classnames(
  'absolute',
  '-translate-x-full',
  'focus-within:translate-x-0',
  'bottom-[-50px]',
  'transition',
  'duration-75'
);
export const supportButton = classnames(
  'text-white',
  'hover:text-nzxt-dark-grey-100'
);

export const contentWrapper = classnames(
  'flex',
  'items-center',
  'max-w-screen-2xl',
  'relative',
  'm-auto',
  'w-full'
);

export const betweenFlexContainer = classnames(
  contentWrapper,
  'justify-between',
  spacing
);

export const topBarContainer = classnames(
  'bg-white',
  'lg:bg-nzxt-light-grey-800',
  'relative',
  'z-10',
  'border-b',
  'lg:border-0',
  'py-1',
  'relative'
);

export const topInnerContainer = classnames(
  betweenFlexContainer,
  // 'lg:py-0',
  'py-3.5'
);

export const linkContainer = classnames(
  'w-full',
  'flex',
  'items-center',
  'justify-start',
  'lg:space-x-3',
  'pl-3',
  'pr-2',
  'xl:px-8',
  'xl:space-x-8',
  'xl:pl-12'
);

export const dropdownContainerBase = classnames(
  flexCenter,
  'justify-end',
  'py-2',
  'z-20',
  'space-x-1.5',
  'xl:space-x-2'
);

export const bottomWrapper = classnames('w-full');

export const bottomBarContainer = classnames(
  contentWrapper,
  'items-center',
  hiddenSmall
);

export const logo = classnames('w-20', 'h-4', 'fill-current', 'text-white');
export const mobileLogo = classnames(
  'w-16',
  'h-4',
  'ml-1',
  'block',
  'lg:hidden'
);

export const input = classnames(
  'border-none',
  'focus:outline-none',
  'focus:ring-transparent',
  'focus:ring',
  'outline-none',
  'focus:outline-none',
  'focus:shadow-none',
  'focus:ring-nzxt-volt-400',
  'focus:ring-1',
  'focus:border-nzxt-volt-400',
  'appearance-none',
  'block',
  'w-full',
  'px-3',
  'py-2',
  'border',
  'border-gray-300',
  'rounded-md',
  'shadow-sm',
  'placeholder-gray-400',
  'sm:text-sm',
  'w-48'
);

export const marketingBannerWrapper = classnames('h-global-banner');
