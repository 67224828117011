import { memo, FC } from 'react';
import type { DatoFooter } from '@framework/api/types';
import FooterLangControl from '@components/Layout/Footer/FooterLangControl';
// import FooterLinkColumns from './FooterLinkColumns';
import FooterSocial from './FooterSocial';
import * as styles from './styles';
import FooterBottomRow from './FooterBottomRow';

type Props = {
  data?: DatoFooter;
};

const FooterComponent: FC<Props> = ({ data }) =>
  data ? (
    <footer id="footer" className={styles.backgroundWrapper}>
      <div className={styles.outer}>
        <div className={styles.utilityLinks}>
          <FooterLangControl footer />
          <div className={styles.socialLinksDesktop}>
            <FooterSocial socialLinks={data.socialLinks} />
          </div>
        </div>
        {/* <div className={styles.inner}>
          <FooterLinkColumns columns={data.columns} />
        </div> */}
        <div className={styles.socialLinksMobile}>
          <FooterSocial socialLinks={data.socialLinks} idSuffix="mobile" />
        </div>
        <FooterBottomRow legalLinks={data.legalLinks} />
      </div>
    </footer>
  ) : null;

export default memo(FooterComponent);
