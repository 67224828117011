import { FC } from 'react';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

const FooterCookieControlLink: FC = () => {
  const handleClick = (): void => {
    if (
      typeof window !== 'undefined' &&
      typeof window.CookieControl !== 'undefined'
    ) {
      window.CookieControl.open();
    }
  };

  return (
    <span
      className={styles.anchor}
      onClick={() => handleClick()}
      onKeyDown={() => handleClick()}
      role="button"
      tabIndex={0}
    >
      {t('manage_cookie_preferences')}
    </span>
  );
};

export default FooterCookieControlLink;
