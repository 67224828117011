import { FC, CSSProperties, memo } from 'react';
import proxyImage from '@utils/proxy-image';

const isSsr = typeof window === 'undefined';
const universalBtoa = isSsr
  ? (str: string) => Buffer.from(str.toString(), 'binary').toString('base64')
  : window.btoa;

export type ResponsiveImageType = {
  /** The aspect ratio (width/height) of the image */
  aspectRatio: number;
  /** A base64-encoded thumbnail to offer during image loading */
  base64?: string;
  /** The height of the image */
  height?: number;
  /** The width of the image */
  width: number;
  /** The HTML5 `sizes` attribute for the image */
  sizes?: string;
  /** The fallback `src` attribute for the image */
  src?: string;
  /** The HTML5 `srcSet` attribute for the image */
  srcSet?: string;
  /** The HTML5 `srcSet` attribute for the image in WebP format, for browsers that support the format */
  webpSrcSet?: string;
  /** The background color for the image placeholder */
  bgColor?: string;
  /** Alternate text (`alt`) for the image */
  alt?: string;
  /** Title attribute (`title`) for the image */
  title?: string;
};

type Props = {
  /** The actual response you get from a DatoCMS `responsiveImage` GraphQL query */
  data: ResponsiveImageType;
  /** Additional CSS className for root node */
  className?: string;
  /** Set loading priority */
  loading?: 'lazy' | 'eager';
  /** Set decoding hint */
  decoding?: 'auto' | 'async' | 'sync';
  /** Set image width */
  width?: number;
  /** Set image height */
  height?: number;
  pictureStyle?: CSSProperties;
  explicitWidth?: boolean;
  pictureClassName?: string;
  style?: CSSProperties;
  // Should we proxy this image or use the original source
  proxy?: boolean;
  id?: string;
  dataTestID?: string;
};

const absolutePositioning: CSSProperties = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

export const Image: FC<Props> = ({
  className,
  data,
  decoding = 'async',
  explicitWidth,
  height,
  loading = 'lazy',
  pictureClassName,
  pictureStyle,
  style,
  id,
  width,
  proxy = true,
  dataTestID,
}) => {
  const imageData = proxy ? proxyImage(data) : data;

  const webpSource = imageData.webpSrcSet && (
    <source
      srcSet={imageData.webpSrcSet}
      sizes={imageData.sizes}
      type="image/webp"
    />
  );

  const regularSource = imageData.srcSet && (
    <source srcSet={imageData.srcSet} sizes={imageData.sizes} />
  );

  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${imageData.width}" height="${imageData.height}"></svg>`;

  const sizer = (
    <img
      className={pictureClassName}
      style={{
        display: 'block',
        width: explicitWidth ? `${imageData.width}px` : '100%',
        ...pictureStyle,
      }}
      height={imageData.height}
      width={imageData.width}
      src={`data:image/svg+xml;base64,${universalBtoa(svg)}`}
      alt={imageData?.alt || ''}
    />
  );

  return (
    <div
      data-test-id={dataTestID ?? 'image-block'}
      className={className}
      style={{
        display: explicitWidth ? 'inline-block' : 'block',
        overflow: 'hidden',
        ...style,
        position: 'relative',
      }}
      aria-hidden
    >
      {sizer}
      <picture>
        {webpSource}
        {regularSource}
        {imageData.src && (
          <img
            width={width || imageData.width}
            height={height || imageData.height}
            src={imageData.src}
            alt={imageData.alt}
            title={imageData.title}
            className={className}
            id={id}
            decoding={decoding}
            style={{
              ...absolutePositioning,
              ...pictureStyle,
            }}
            loading={loading}
          />
        )}
      </picture>
    </div>
  );
};

export default memo(Image);
