/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback } from 'react';
import type { HookFetcher } from '../utils/types';
import useCommerceLogout from '../commerce/use-logout';
import type { LogoutBody, LogoutResponseBody } from '../api/auth';
import useCustomer from '../customer/use-customer';

const defaultOpts = {
  url: '/api/maxify/auth',
  method: 'DELETE',
};

export type LogoutInput = LogoutBody;
export type LogoutResult = LogoutResponseBody;

export const fetcher: HookFetcher<LogoutResponseBody, LogoutBody> = (
  options,
  { clientId },
  fetch
) =>
  fetch({
    ...defaultOpts,
    ...options,
    url: `${defaultOpts.url}${clientId ? `?client_id=${clientId}` : ''}`,
  });

export function extendHook(customFetcher: typeof fetcher) {
  const useLogout = () => {
    const { mutate } = useCustomer();
    const fn = useCommerceLogout<LogoutResult, LogoutInput>(
      defaultOpts,
      customFetcher
    );

    return useCallback(
      async function logout(input?: LogoutInput) {
        const data = await fn(input);
        await mutate(null, false);
        return data;
      },
      [fn, mutate]
    );
  };

  useLogout.extend = extendHook;

  return useLogout;
}

export default extendHook(fetcher);
